import React, { useEffect, useRef, useState } from 'react';
// import GraphicalView from './views/GraphicalView';
import CalendarView from './views/CalendarView';
import useAuth from '../../hooks/useAuth';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import dayjs from 'dayjs';
import ListView from './views/ListView';
import { Button, Divider } from '@mui/material';
import AddTasksModal from './modals/AddTaskModal';
import Skeleton from '@mui/material/Skeleton';
import DeleteTaskModal from './modals/DeleteTaskModal';
import EditTaskModal from './modals/EditTaskModal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const EventsContainer = props => {
    const monthStrings = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [containerHeight, setContainerHeight] = useState('95vh');
    const [startDate, setStartDate] = useState(dayjs().startOf('day'));
    const [circledDate, setCircledDate] = useState(dayjs().startOf('day'));

    const [tasks, setTasks] = useState([]);
    const [upcomingTasks, setUpcomingTasks] = useState([]);

    const [taskView, setTaskView] = useState('upcoming');
    const [overdueTasks, setOverdueTasks] = useState([]);

    var init = useRef(true);

    const [data, setData] = useState([]);

    const axiosProtect = useAxiosProtect();
    const [isLoading, setIsLoading] = useState(true);

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTasks, setSelectedTasks] = useState([]);

    const [tasksModalOpen, setTasksModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [taskToEdit, setTaskToEdit] = useState(null);
    const [editMode, setEditMode] = useState('status');

    const [modalLoading, setModalLoading] = useState(false);

    const {auth, persist} = useAuth();
    const [currentSite, setCurrentSite] = useState(window.location.href.split('/').slice(-1)[0]);
    const [siteList, setSiteList] = useState([]);

    useEffect(() => {
        const updateContainerHeight = () => {
            let navbar = document.querySelector('.navbar');
            let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];
  
            let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
            let windowHeight = window.innerHeight;
            let remainingHeight = windowHeight - navbarHeight;
  
            setContainerHeight(`${remainingHeight}px`);
        };
      
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
            window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    const generateGraphData = (taskArr) => {
        const daysInMonth = new Date(startDate['$y'], startDate['$M'] + 1, 0).getDate()
        let monthIndex= new Date(startDate['$y'], startDate['$M'] + 1, 0).getMonth();
        let year = new Date(startDate['$y'], startDate['$M'] + 1, 0).getFullYear();

        let graphData = [];

        for(let i = 0;i<daysInMonth;++i){
            graphData.push({
                date: i + 1,
                value: 0,
                tasks: []
            });
        }

        for(let i = 0;i<taskArr.length;++i){
            let taskDate = new Date(taskArr[i].deadline);

            if(taskDate.getFullYear() === year && taskDate.getMonth() === monthIndex){
                let index = new Date(taskArr[i].deadline).getDate() - 1;

                let tempObj = graphData[index];
                tempObj.value += 1;
                tempObj.tasks.push(taskArr[i])
                graphData[index] = tempObj;
            }
        }

        return graphData;
    }

    useEffect(() => {
        setIsLoading(true);
        setCircledDate(startDate);
        const stDate = new Date(startDate.toString());

        axiosProtect.get(`/t/tasks?${props.oppView ? 'oppID' : 'siteID'}=${window.location.href.split('/').slice(-1)[0]}&month=${stDate.getMonth()}&year=${stDate.getFullYear()}`).then(response => {
            setTasks([...response.data.tasks]);
            setUpcomingTasks([...response.data.upcomingTasks]);
            setOverdueTasks([...response.data.overdueTasks]);

            setSelectedDate(formatDate(startDate.toString()));

            let graphData = generateGraphData(response.data.tasks);

            if(init.current){
                init.current = false;

                const currentDate = new Date();
                const currentDay = currentDate.getDate();

                setSelectedTasks(graphData[currentDay - 1].tasks);

                axiosProtect.get(`/auth/query/list-client-site-access?uid=${auth.id}`).then(response => {
                    setSiteList(response.data.sites);
                });
            }else{
                setSelectedTasks(graphData[0].tasks);
            }

            setData(graphData);

            setIsLoading(false);

        }).catch(err => {

        });
    }, [startDate]);

    const updateTasks = callMethod => {
        const stDate = new Date(startDate.toString());
        
        axiosProtect.get(`/t/tasks?${props.oppView ? 'oppID' : 'siteID'}=${window.location.href.split('/').slice(-1)[0]}&month=${stDate.getMonth()}&year=${stDate.getFullYear()}`).then(response => {
            setTasks([...response.data.tasks]);
            setUpcomingTasks([...response.data.upcomingTasks]);
            setOverdueTasks([...response.data.overdueTasks]);

            let graphData = generateGraphData(response.data.tasks);

            const selDate = new Date(selectedDate).getDate();

            setSelectedTasks(graphData[selDate - 1].tasks);

            setData(graphData);

            if(callMethod === 'create'){
                setTasksModalOpen(false);
            }else if(callMethod === 'delete'){
                setTaskToDelete(null);
                setDeleteModalOpen(false);
            }else if(callMethod === 'edit'){
                setTaskToEdit(null);
                setEditModalOpen(false);
            }

            setModalLoading(false);
        }).catch(err => {
            setModalLoading(false);
        });
    }

    const deleteTask = (taskID, isRecurring, deletionType) => {
        setModalLoading(true);

        axiosProtect.delete(`/t/tasks?taskID=${taskID}&isRecurring=${isRecurring}&deletionType=${deletionType}`).then(response => {
            updateTasks('delete');
        }).catch(err => {
            setTaskToDelete(null);
            setDeleteModalOpen(false);
            setModalLoading(false);
        });
    }

    const addTask = newTask => {
        setModalLoading(true);
        updateTasks('create');
    }

    const updateTaskStatus = (taskID, scope, newStatus, isRecurring) => {
        setModalLoading(true);
        let payload = {taskID, scope, newStatus, isRecurring};

        axiosProtect.put(`/t/tasks/edit-status`, payload).then(response => {
            updateTasks('edit');
        }).catch(err => {
            setTaskToEdit(null);
            setEditModalOpen(false);
            setModalLoading(false);
        });
    }

    const editTask = payload => {
        axiosProtect.put(`/t/tasks`, payload).then(response => {
            updateTasks('edit');
        }).catch(err => {
            setTaskToEdit(null);
            setEditModalOpen(false);
            setModalLoading(false);
        });
    }

    const formatDate = timeString => {
        const date = new Date(timeString);

        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
      
        return date.toLocaleString('en-US', options);
    }

    return (
        <div style={{padding:'1% 2%', backgroundColor:'#f5f5f5', height: containerHeight, overflowX:'hidden'}}>
            {tasksModalOpen && <AddTasksModal tasksModalOpen={tasksModalOpen} setTasksModalOpen={setTasksModalOpen} addTask={addTask} oppView={props.oppView} formatDate={formatDate} modalLoading={modalLoading}/>}
            {deleteModalOpen && <DeleteTaskModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} taskToDelete={taskToDelete} deleteTask={deleteTask} modalLoading={modalLoading}/>}
            {editModalOpen && <EditTaskModal editModalOpen={editModalOpen} setEditModalOpen={setEditModalOpen} taskToEdit={taskToEdit} oppView={props.oppView} modalLoading={modalLoading} editMode={editMode} updateTaskStatus={updateTaskStatus} editTask={editTask}/>}

            {
                props.oppView ?
                <>
                <div style={{height:'fit-content', paddingBottom:'1vh'}}>
                    <Button style={{backgroundColor:'rgba(8,114,182, 0.1)'}} onClick={() => setTasksModalOpen(true)}>Add Task</Button>
                </div>
                <p style={{textAlign:'center'}}>Upcoming (next 7 days)</p>
                <ListView startDate={startDate} setStartDate={setStartDate} tasks={upcomingTasks} setDeleteModalOpen={setDeleteModalOpen} setTaskToDelete={setTaskToDelete} 
                    oppView={props.oppView} setEditModalOpen={setEditModalOpen} taskToEdit={taskToEdit} setTaskToEdit={setTaskToEdit} setEditMode={setEditMode}/>
                
                <br/>

                <p style={{textAlign:'center'}}>Overdue</p>
                <ListView startDate={startDate} setStartDate={setStartDate} tasks={overdueTasks} setDeleteModalOpen={setDeleteModalOpen} setTaskToDelete={setTaskToDelete} 
                    oppView={props.oppView} setEditModalOpen={setEditModalOpen} taskToEdit={taskToEdit} setTaskToEdit={setTaskToEdit} setEditMode={setEditMode}/>
                
                <br/>
                <br/>
                </>
                :
                <div style={{height:'100%', width:'100%',  display:'flex', flexDirection:'row'}}>
                    <div style={{flex:'5',  height:'100%', display:'flex', flexDirection:'column'}}>

                        <CalendarView startDate={startDate} setStartDate={setStartDate} data={data} 
                            setSelectedTasks={setSelectedTasks} setSelectedDate={setSelectedDate} 
                            formatDate={formatDate} isLoading={isLoading} circledDate={circledDate} setCircledDate={setCircledDate}/>

                        <Divider/>

                        <div style={{flex:'1', width:'100%',  overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                            {(selectedDate !== '' && !isLoading) && <h4 style={{textAlign:'center', marginTop:'1%'}}>Tasks for {selectedDate}</h4>}
                            {/* {selectedDate !== '' && <p style={{textAlign:'center', color:'red', textDecoration:'underline', cursor:'pointer'}} onClick={() => {
                                setSelectedDate('');
                                setSelectedTasks([]);
                            }}>Dismiss</p>} */}
                            {
                                !isLoading &&
                                <>
                                {
                                    (selectedTasks.length > 0) ?
                                    <ListView startDate={startDate} setStartDate={setStartDate} tasks={selectedTasks} setDeleteModalOpen={setDeleteModalOpen} setTaskToDelete={setTaskToDelete} 
                                        condensedView={true} setEditModalOpen={setEditModalOpen} taskToEdit={taskToEdit} setTaskToEdit={setTaskToEdit} setEditMode={setEditMode}/>
                                    :
                                    <p style={{textAlign:'center', color:'grey'}}>No tasks</p>
                                }
                                </>
                            }
                        </div>

                        <div style={{height:'1vh'}}></div>
                    </div>

                    <div style={{width:'5vw'}}></div>

                    <div style={{width:'fit-content', display:'flex', flexDirection:'column'}}>
                        <div style={{height:'fit-content', paddingBottom:'1vh', display:'flex', flexDirection:'row'}}>
                            <Button style={{backgroundColor:'rgba(8,114,182, 0.1)', flex:'1', height:'fit-content'}} onClick={() => setTasksModalOpen(true)}>Add Task</Button>

                            <div style={{flex:'4'}}></div>

                            {(auth.role !== process.env.REACT_APP_ES_ADMIN && auth.role !== process.env.REACT_APP_ES_USER) && <Box sx={{ minWidth: '5vw', marginBottom: '1vh' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Site</InputLabel>
                                    <Select
                                        value={currentSite}
                                        label="Site"
                                        onChange={e => {
                                            if(e.target.value === currentSite) return;
                                            setCurrentSite(e.target.value);
                                            window.history.replaceState({}, '', `/schedule/${e.target.value}`);
                                            updateTasks('create');
                                        }}
                                    >
                                        {
                                            siteList.map((site, i) => (
                                                <MenuItem value={site.id.toString()}>{site.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>}
                        </div>

                        <div style={{
                            flex:'1', backgroundColor:'white', borderRadius:'1%', padding:`${isLoading ? '0' : '2%'} 0`, boxShadow:'2px 6px 12px rgb(164, 164, 164)', height:'100%', 
                            overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none', width:'50vw'
                        }}>
                            {
                                isLoading ? 
                                <Skeleton variant="rounded" sx={{width:'100%', height:'100%'}} animation="wave"/> :
                                <>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <div style={{flex:'1', textAlign:'left'}}>
                                        <Button style={{backgroundColor:`rgba(8,114,182, ${taskView === 'upcoming' ? '0.1' : '0'})`}} onClick={() => setTaskView('upcoming')}>Upcoming</Button>
                                    </div>

                                    <h4 style={{textAlign:'center'}}>{taskView.charAt(0).toUpperCase() + taskView.slice(1)} tasks</h4>

                                    <div style={{flex:'1', textAlign:'right'}}>
                                        <Button style={{backgroundColor:`rgba(8,114,182, ${taskView === 'overdue' ? '0.1' : '0'})`}} onClick={() => setTaskView('overdue')}>Overdue</Button>
                                    </div>
                                </div>
                                {
                                    taskView === 'upcoming' ?
                                    <>
                                    {
                                        upcomingTasks.length === 0 ?
                                        <p style={{textAlign:'center', color:'grey'}}>No upcoming tasks in the next 7 days</p>
                                        :
                                        <ListView startDate={startDate} setStartDate={setStartDate} 
                                            tasks={upcomingTasks} setDeleteModalOpen={setDeleteModalOpen} 
                                            setTaskToDelete={setTaskToDelete} oppView={props.oppView}
                                            setEditModalOpen={setEditModalOpen} taskToEdit={taskToEdit} setTaskToEdit={setTaskToEdit} setEditMode={setEditMode}
                                        />
                                    }
                                    </>
                                    :
                                    <>
                                    {
                                        overdueTasks.length === 0 ?
                                        <p style={{textAlign:'center', color:'grey'}}>No overdue tasks</p>
                                        :
                                        <ListView startDate={startDate} setStartDate={setStartDate} 
                                            tasks={overdueTasks} setDeleteModalOpen={setDeleteModalOpen} 
                                            setTaskToDelete={setTaskToDelete} oppView={props.oppView}
                                            setEditModalOpen={setEditModalOpen} taskToEdit={taskToEdit} setTaskToEdit={setTaskToEdit} setEditMode={setEditMode}
                                        />
                                    }
                                    </>
                                }
                                </>
                            }
                        </div>

                        <div style={{height:'2vh'}}></div>

                        <div style={{
                            flex:'1', backgroundColor:'white', borderRadius:'1%', padding:`${isLoading ? '0' : '2%'} 0`, boxShadow:'2px 6px 12px rgb(164, 164, 164)', height:'100%', 
                            overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none', width:'50vw'
                        }}>
                            {
                                isLoading ? 
                                <Skeleton variant="rounded" sx={{width:'100%', height:'100%'}} animation="wave"/> :
                                <>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <div style={{flex:'1', textAlign:'left'}}>
                                        {/* <Button disabled={tasks.length === 0}>Filter</Button> */}
                                    </div>

                                    <h4 style={{textAlign:'center', flex:'1'}}>Tasks for {monthStrings[startDate['$M']]} {startDate['$y']}</h4>

                                    <div style={{flex:'1', textAlign:'right'}}>
                                        {/* <Button disabled={tasks.length === 0}>Reset Filter</Button> */}
                                    </div>
                                </div>
                                {
                                    tasks.length === 0 ? 
                                    <p style={{textAlign:'center', color:'grey'}}>No tasks this month</p>
                                    :
                                    <ListView startDate={startDate} setStartDate={setStartDate} tasks={tasks} 
                                        setDeleteModalOpen={setDeleteModalOpen} setTaskToDelete={setTaskToDelete} 
                                        oppView={props.oppView} setEditModalOpen={setEditModalOpen} taskToEdit={taskToEdit} setTaskToEdit={setTaskToEdit} setEditMode={setEditMode}
                                    />
                                }
                                </>
                            }
                        </div>

                        <div style={{height:'1vh'}}></div>
                    </div>
                </div>
            }
        </div>
    );
}

export default EventsContainer;