import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const TankTable = props => {
    const columns = [
        {
            id: 'name',
            label: 'Name',
            minWidth: 'fit-content',
            align: 'left'
        },
        {
            id: 'tank_height',
            label: 'Tank height (cm)',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'topup_height',
            label: 'Top-up height (cm)',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'city_height',
            label: 'City water height (cm)',
            minWidth: 'fit-content',
            align: 'right'
        },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                <strong>{column.label}</strong>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tanks.map((tank, i) => (
                            <TableRow key={tank.id}>
                                <TableCell align='left'>{tank.name}</TableCell>
                                <TableCell align='center'>{Number(Math.round(100 * tank.tank_height_cm) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                <TableCell align='center'>{Number(Math.round(100 * tank.topup_height_cm) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                <TableCell align='center'>{Number(Math.round(100 * tank.city_water_height_cm) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default TankTable;