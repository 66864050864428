

import React, { useState, useRef, useEffect } from 'react';
import { SpeedDial, SpeedDialIcon, Box, TextField, Button } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import Zoom from '@mui/material/Zoom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import useAuth from '../../hooks/useAuth';

const CustomerFeedback = () => {
    const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
    const speedDialRef = useRef();

    const maxMessageLength = 1000;

    const [feedbackForm, setFeedbackForm] = useState({
        message:'',
        messageErr:'',
    });

    const [submissionMessage, setSubmissionMessage] = useState('');

    const axiosProtect = useAxiosProtect();
    const {auth, authContext} = useAuth();

    useEffect(() => {
        if(feedbackFormOpen) return;

        setFeedbackForm({
            message:'',
            messageErr:'',
        });

    }, [feedbackFormOpen]);

    const handleClick = e => {
        if (e.target.closest(`#${speedDialRef.current.id}`)) {
            setFeedbackFormOpen(!feedbackFormOpen);
        }
    }

    const validateForm = () => {
        let isValid = true;

        console.log('here')

        if(feedbackForm.message.trim() === ''){
            isValid = false;
            setFeedbackForm(prev => ({
                ...prev,
                ['messageErr']: 'Required'
            }));
        }else{
            setFeedbackForm(prev => ({
                ...prev,
                ['messageErr']: ''
            }))
        }

        return isValid;
    }

    const submitForm = () => {
        if(!validateForm()) return;

        let payload = {
            client_uid: auth.id,
            message: feedbackForm.message.trim(),
            customer_org_id: auth.org_id
        }

        axiosProtect.post('/mgmt/customer-mgmt', payload).then(response => {
            setSubmissionMessage('success');
            setFeedbackFormOpen(false);
        }).catch(err => {
            setSubmissionMessage('error');
            setFeedbackFormOpen(false);
        })
    }

    return (
        <Box sx={{ height: 320, flexGrow: 1, position:'fixed',left:'0', bottom:'0',zIndex: '1001', margin:'0 0 13rem 2.5%' }}>
            <Snackbar open={submissionMessage !== ''} autoHideDuration={submissionMessage === 'success' ? 3000 : 5000} onClose={() => setSubmissionMessage('')}>
                <Alert
                    onClose={() => setSubmissionMessage('')}
                    severity={submissionMessage}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {submissionMessage === '' ? '' : submissionMessage === 'success' ? 'Your feedback has been submitted!' : 'Could not submit feedback. Please reach out to your Enviro-Stewards contact.'}
                </Alert>
            </Snackbar>

            <SpeedDial
                ariaLabel="SpeedDial for Customer Feedback"
                ref={speedDialRef}
                id="speed-dial-button"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    left: 16,
                    margin:'0 0 1vw 1vw',
                    '& .MuiSpeedDialIcon-icon': {
                        transform: 'none !important'
                    },
                    '& .MuiSpeedDialIcon-iconOpen': {
                        transform: 'none !important'
                    },
                }}
                onClick={handleClick}
                icon={<SpeedDialIcon icon={feedbackFormOpen ? <CloseIcon/> : <MessageIcon/>}/>}
            />
            <Zoom in={feedbackFormOpen}>
                <div 
                    style={{ 
                        height: 'fit-content', 
                        padding:'10px 15px', 
                        width: 'fit-content', 
                        backgroundColor: 'white', 
                        position: 'fixed', 
                        zIndex: '1002', 
                        boxShadow:'2px 6px 12px rgba(78,79,83,0.75)', 
                        borderRadius:'10px 10px 10px 0px', 
                        border:'0.15rem solid rgb(0,177,168)' 
                    }} 
                    id="feedback-form"
                >
                    <h4 style={{textAlign:'center'}}>Customer Feedback</h4>

                    <div style={{height:'fit-content', width:'15vw', margin:'auto', textAlign:'center'}}>
                        <p style={{fontSize:'0.8rem'}}>Leave us a message here if you have questions, feature requests, or performance issues.</p>
                    </div>

                    <TextField
                        id="outlined-multiline-static"
                        label="Feedback"
                        style={{width:'25rem'}}
                        multiline
                        value={feedbackForm.message}
                        onChange={e => {
                            if(e.target.value.length > maxMessageLength) return;

                            setFeedbackForm(prev => ({
                                ...prev,
                                ['message']: e.target.value
                            }));
                        }}
                        rows={8}
                        variant="outlined"
                        error={feedbackForm.messageErr !== ''}
                        helperText={feedbackForm.messageErr !== '' ? feedbackForm.messageErr : `${maxMessageLength - feedbackForm.message.length} character${maxMessageLength - feedbackForm.message.length === 1 ? '' : 's'} left`}
                    />

                    <div style={{width:'25rem', display:'flex', flexDirection:'row'}}>
                        <div style={{width:'fit-content'}}>
                            <Button onClick={() => setFeedbackFormOpen(false)}>Cancel</Button>
                        </div>

                        <div style={{flex:'1'}}></div>

                        <div style={{width:'fit-content'}}>
                            <Button variant="contained" onClick={() => submitForm()}>Submit</Button>
                        </div>
                    </div>
                </div>
            </Zoom>
        </Box>
    );
};

export default CustomerFeedback;