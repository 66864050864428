import React, {useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactSpeedometer from "react-d3-speedometer";
import './gaugeStyle.css';

const Gauge = props => {
    return (
        // <div className="gauge-center">
        <div className="overview-speedometer">
            <ReactSpeedometer
                maxValue={100}
                ringWidth={60}
                customSegmentStops={[
                    0,
                    // 10,
                    20,
                    // 30,
                    40,
                    // 50,
                    60,
                    // 70,
                    80,
                    // 90,
                    100
                ]}
                
                needleTransitionDuration={5000}
                needleTransition="easeElastic"
                currentValueText="${value} %"
                value={`${props.pct}`}
            />
        </div>
        // </div>
    );

}

export default Gauge;