import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Autocomplete, Box, FormHelperText, IconButton, TextField } from '@mui/material';

const TargetModal = props => {
    const [page, setPage] = useState(1);
    const [baselineData, setBaselineData] = useState({
        year: '',
        water: {amount: '', ghg: '', unitCost: ''},
        electricity: {amount: '', ghg: '', unitCost: ''},
        gas: {amount: '', ghg: '', unitCost: '', btuPerUnit: '', units: 'm3'},
    });

    const [targetData, setTargetData] = useState({
        year: '',
        water: {percent:'', amount: ''},
        electricity: {percent:'', amount: ''},
        gas: {percent:'', amount: ''},
        ghg: {percent:'', amount: ''},
        cost: {percent:'', amount: ''},
    });

    return (
        <Modal
            show={props.targetModalState !== ''}
            onHide={() => props.closeModal()}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4>Add target</h4>
            </Modal.Header>

            <Modal.Body>
                {
                    page === 1 ?
                    <>
                        <p>Baseline data</p>
                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                        
                        <br/>
                        <br/>

                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                    </>
                    :
                    <>
                        <TextField
                            fullWidth
                            label="Year"
                            // value={ctForm.name.value}
                            // onChange={updateFormValue}
                            // error={ctForm.name.error !== ''}
                            // helperText={ctForm.name.error}
                        />
                    </>
                }

            </Modal.Body>

            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

export default TargetModal;