import React, {useEffect, useRef, useState} from 'react';
// import { Divider, TextField } from '@mui/material';
// import OrgCard from '../dashboard_components/orgs/OrgCard';
// import AddCardIcon from '@mui/icons-material/AddCard';/
// import OrgModal from '../dashboard_components/orgs/OrgModal';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import carbonOneLogo from '../../../media/carbonone_logo.webp';

const APIDashboardContainer = props => {
    const axiosProtect = useAxiosProtect();

    const [values, setValues] = useState({
        apiKey: '',
        showAPIKey: false,
    });

    const handleClickShowAPIKey = () => {
        setValues({
            ...values,
            showAPIKey: !values.showAPIKey,
        });
    };

    const handleMouseDownAPIKey = (event) => {
        event.preventDefault();
    };

    var init = useRef(true);
    const [apiStatus, setAPIStatus] = useState('');

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        axiosProtect.get('/api/api/carbonone').then(response => {
            setAPIStatus(response.data.status);
        }).catch(error => {
            setAPIStatus('error');
        });
    }, []);

    const updateAPIKey = () => {
        axiosProtect.post('/api/api/carbonone').then(response => {
            setValues({
                ...values,
                apiKey: response.data.apiKey
            });
            setAPIStatus('active');
        }).catch(error => {
            setAPIStatus('error');
        });
    }

    return(
        <div style={{padding:'2%', backgroundColor:'#f5f5f5', minHeight:'95vh'}}>
            <img src={carbonOneLogo} style={{height:'10vh', width:'auto'}}></img>
            <br/>
            <h4>API key status: <span style={{color:`${apiStatus === 'active' ? '#28a745' : '#dc3545'}`}}>{apiStatus}</span></h4>
            <br/>
            <TextField
                variant='outlined'
                fullWidth
                label='API key'
                value={values.apiKey}
                InputLabelProps={{ shrink: true }}
                placeholder='your-api-key'
                type={values.showAPIKey ? 'text' : 'password'}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="start">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowAPIKey}
                            onMouseDown={handleMouseDownAPIKey}
                            >
                            {values.showAPIKey ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button onClick={() => updateAPIKey()}>Generate key</Button>
            <br/>
            <small>Note: API keys are hashed and therefore are not retrievable. Please ensure you copy and store the generated key before exiting.</small>
        </div>
    )
}

export default APIDashboardContainer;