import React from 'react';
import { Modal } from 'react-bootstrap';

const PaybackModal = props => {
    return(
        <Modal
            show={props.paybackModalOpen}
            onHide={() => props.setPaybackModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4 style={{fontSize:'1.2rem', margin:'0'}}>Payback Definition</h4>
            </Modal.Header>

            <Modal.Body>
                <p style={{margin:'0'}}>Years needed to recover capital investment. Unadjusted for inflation or depreciation.</p>
            </Modal.Body>
        </Modal>
    );
}

export default PaybackModal;