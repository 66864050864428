import { Autocomplete, Box, FormHelperText, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import { HelpCenter } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import useAxiosProtect from '../../../../../../hooks/useAxiosProtect';
import { electricUnitTypes } from '../../../../../../example_data/dropdowns/clientModal/loggingComponents';

const CTModal = props => {
    const nameTip = 'Name for the unit being monitored. Used as primary name for RMIS metrics';
    const voltageTip = "Supply voltage for the monitored unit";
    const pfTip = "Power factor to be used for computing unit's energy metrics";
    const centricaTip = "Device's name as shown on Power Radar. Copy+paste to help avoid issues";
    const unitTip = "Best fit for type of electrical unit the CT is logging";
    const edgecomDeviceTip = "Device's name as shown on Edgecom's website. Copy+paste to help avoid issues";
    const edgecomLocationTip = "Name of location which the device is registered under as shown on Edgecom's website. Copy+paste to help avoid issues";

    const axiosProtect = useAxiosProtect();

    const [ctForm, setCTForm] = useState({
        ctType: 'edgecom',
        name: {
            value: '',
            error: ''
        },
        voltage: {
            value: '',
            error: ''
        },
        power_factor: {
            value: '',
            error: ''
        },
        centrica_device_name: {
            value: '',
            error: ''
        },
        edgecom_device_name: {
            value: '',
            error: ''
        },
        edgecom_location_name: {
            value: '',
            error: ''
        }
    });
    const [customPF, setCustomPF] = useState(true);
    const [sitePF, setSitePF] = useState(-1);

    const [serverErrorMessage, setServerErrorMessage] = useState('');

    const [unitTypes, setUnitTypes] = useState(electricUnitTypes);
    const [autoCompleteValue, setAutoCompleteValue] = useState(null);
    const [autoCompleteError, setAutoCompleteError] = useState('');
    const [hasVFD, setHasVFD] = useState(false);

    const floatRegex = /^(?!-)(\d*\.?\d*)?$/;

    const updateFormValue = e => {
        let field = e.target.id.split('-')[3];

        if(!field.includes('name') && !floatRegex.test(e.target.value)) return;

        setCTForm(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: e.target.value
            }
        }));
    }

    const handlePFClick = async () => {
        let pfValue = '';

        if(customPF){
            if(sitePF !== -1){
                pfValue = sitePF;

            }else{
                try{
                    let response = await axiosProtect.get(`/s/sites/get-site-fields?fields=%5B%22power_factor%22%5D&siteID=${props.siteID}`);
    
                    pfValue = parseFloat(response.data.siteData?.power_factor);
    
                    if(isNaN(pfValue)) return;

                    setSitePF(pfValue);
    
                }catch(err){
                    return;
                }
            }
        }

        setCTForm(prev => ({
            ...prev,
            ["power_factor"]: {
                ...prev["power_factor"],
                value: pfValue
            }
        }));

        setCustomPF(custom => !custom);
    }

    const isValidForm = () => {
        let isValid = true;
        let temp = {...ctForm};

        for(var key in ctForm){
            if(key === 'ctType') continue;

            if(key === 'centrica_device_name' && ctForm.ctType === 'edgecom'){
                temp[key].error = "";
            }else if((key === 'edgecom_device_name' || key === 'edgecom_location_name') && ctForm.ctType === 'centrica'){
                temp[key].error = "";
            }else{
                if(ctForm[key].value === ''){
                    temp[key].error = "Required";
                    isValid = false;
    
                }else if(key === 'voltage' && isNaN(parseInt(ctForm[key].value))){
                    temp[key].error = "Invalid";
                    isValid = false;
    
                }else if(key === 'power_factor' && (isNaN(parseFloat(ctForm[key].value)) || parseFloat(ctForm[key].value) < 0 || parseFloat(ctForm[key].value) > 1)){
                    temp[key].error = "Invalid";
                    isValid = false;
                    
                }else{
                    temp[key].error = "";
                }
            }
        }

        if(!autoCompleteValue){
            setAutoCompleteError("Required");
            isValid = false;
        }else setAutoCompleteError("");

        setCTForm(temp);
        return isValid;
    }

    const addCT = async () => {
        setServerErrorMessage('');

        if(!isValidForm()) return;

        if(ctForm.ctType === 'centrica'){
            let payload = {
                site_id: parseInt(props.siteID),
                name: ctForm.name.value,
                voltage: parseInt(ctForm.voltage.value),
                power_factor: parseFloat(ctForm.power_factor.value),
                centrica_device_name: ctForm.centrica_device_name.value,
                unit_type: autoCompleteValue.value,
                has_vfd: hasVFD
            }
    
            try{
                let response = await axiosProtect.post('/ct/centrica/ct', payload);
                if(response.status === 200){
                    props.addCT(response.data.centricaCT);
                    props.closeModal();
                }
                
            }catch(err){
                setServerErrorMessage(err.response.data.error);
            }
        }else if(ctForm.ctType === 'edgecom'){
            let payload = {
                device_type: 'ct',
                site_id: parseInt(props.siteID),
                name: ctForm.name.value,
                voltage: parseInt(ctForm.voltage.value),
                power_factor: parseFloat(ctForm.power_factor.value),
                edgecom_device_name: ctForm.edgecom_device_name.value,
                edgecom_location_name: ctForm.edgecom_location_name.value,
                unit_type: autoCompleteValue.value,
                has_vfd: hasVFD
            }
    
            try{
                let response = await axiosProtect.post('/edge/edgecom', payload);
                if(response.status === 200){
                    props.addCT(response.data.edgecomCT);
                    props.closeModal();
                }
                
            }catch(err){
                setServerErrorMessage(err.response.data.error);
            }
        }
    }

    return(
        <Modal
            show={props.ctModalState !== ''}
            onHide={() => props.closeModal()}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                {props.ctModalState === 'add' && <h4>Add CT</h4>}
            </Modal.Header>

            <Modal.Body>
                {
                    props.ctModalState === 'add' ? 
                    <>

                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Device supplier</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={e => {
                                setCTForm(prev => ({
                                    ...prev,
                                    ['ctType']: e.target.value
                                }));
                            }}
                        >
                            <FormControlLabel value="edgecom" control={<Radio checked={ctForm.ctType === 'edgecom'}/>} label="Edgecom" />
                            <FormControlLabel value="centrica" control={<Radio checked={ctForm.ctType === 'centrica'}/>} label="Centrica" />
                        </RadioGroup>
                    </FormControl>

                    {serverErrorMessage !== '' && <p style={{color:'red', textAlign:'center'}}>{serverErrorMessage}</p>}
                    {/* name, voltage, pwoer factor or use site pf, centrica device name */}
                    <div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
                        <TextField
                            style={{flex:'84'}}
                            id='centrica-ct-modal-name'
                            label="Name"
                            fullWidth
                            value={ctForm.name.value}
                            onChange={updateFormValue}
                            error={ctForm.name.error !== ''}
                            helperText={ctForm.name.error}
                        />

                        <div style={{flex:'2'}}></div>

                        <Tooltip title={nameTip} style={{flex:'14'}}>
                            <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                <HelpCenter/>
                            </IconButton>
                        </Tooltip>
                    </div>

                    <br/>

                    <div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
                        <TextField
                            style={{flex:'84'}}
                            id='centrica-ct-modal-voltage'
                            label="Voltage"
                            fullWidth
                            value={ctForm.voltage.value}
                            onChange={updateFormValue}
                            error={ctForm.voltage.error !== ''}
                            helperText={ctForm.voltage.error}
                        />

                        <div style={{flex:'2'}}></div>

                        <Tooltip title={voltageTip} style={{flex:'14'}}>
                            <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                <HelpCenter/>
                            </IconButton>
                        </Tooltip>
                    </div>

                    <br/>

                    <FormControlLabel control={<Checkbox checked={!customPF} onClick={handlePFClick}/>} label="Use site's listed PF" />

                    <div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
                        <TextField
                            style={{flex:'84'}}
                            id='centrica-ct-modal-power_factor'
                            label="Power factor"
                            fullWidth
                            value={ctForm.power_factor.value}
                            onChange={updateFormValue}
                            disabled={!customPF}
                            error={ctForm.power_factor.error !== ''}
                            helperText={ctForm.power_factor.error}
                        />

                        <div style={{flex:'2'}}></div>

                        <Tooltip title={pfTip} style={{flex:'14'}}>
                            <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                <HelpCenter/>
                            </IconButton>
                        </Tooltip>
                    </div>

                    <br/>

                    {
                        ctForm.ctType === 'centrica' ?
                        <div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
                            <TextField
                                style={{flex:'84'}}
                                id='centrica-ct-modal-centrica_device_name'
                                label="Centrica device name"
                                fullWidth
                                value={ctForm.centrica_device_name.value}
                                onChange={updateFormValue}
                                error={ctForm.centrica_device_name.error !== ''}
                                helperText={ctForm.centrica_device_name.error}
                            />

                            <div style={{flex:'2'}}></div>

                            <Tooltip title={centricaTip} style={{flex:'14'}}>
                                <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                    <HelpCenter/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        :
                        <>
                            <div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
                                <TextField
                                    style={{flex:'84'}}
                                    id='edgecom-ct-modal-edgecom_device_name'
                                    label="Edgecom device name"
                                    fullWidth
                                    value={ctForm.edgecom_device_name.value}
                                    onChange={updateFormValue}
                                    error={ctForm.edgecom_device_name.error !== ''}
                                    helperText={ctForm.edgecom_device_name.error}
                                />

                                <div style={{flex:'2'}}></div>

                                <Tooltip title={edgecomDeviceTip} style={{flex:'14'}}>
                                    <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                        <HelpCenter/>
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <br/>

                            <div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
                                <TextField
                                    style={{flex:'84'}}
                                    id='edgecom-ct-modal-edgecom_location_name'
                                    label="Edgecom location name"
                                    fullWidth
                                    value={ctForm.edgecom_location_name.value}
                                    onChange={updateFormValue}
                                    error={ctForm.edgecom_location_name.error !== ''}
                                    helperText={ctForm.edgecom_location_name.error}
                                />

                                <div style={{flex:'2'}}></div>

                                <Tooltip title={edgecomLocationTip} style={{flex:'14'}}>
                                    <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                        <HelpCenter/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    }

                    <br/>

                    <FormControlLabel control={<Checkbox checked={hasVFD} onClick={() => setHasVFD(vfd => !vfd)}/>} label="This unit has a VFD" />

                    <div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
                        <div style={{width:'100%'}}>
                            <Autocomplete
                                id="centrica-modal-unit-type"
                                options={unitTypes}
                                value={autoCompleteValue}
                                fullWidth
                                getOptionLabel={option => option.text}
                                onChange={(_, option) => setAutoCompleteValue(option)}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        <span>{option.text}</span>
                                    </Box>
                                )}
                                renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Unit type" />}
                            />
                            {autoCompleteError !== '' && <FormHelperText error>
                                {autoCompleteError}
                            </FormHelperText>}
                        </div>

                        <div style={{flex:'2'}}></div>

                        <Tooltip title={unitTip} style={{flex:'14'}}>
                            <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                <HelpCenter/>
                            </IconButton>
                        </Tooltip>
                    </div>

                    </> : props.ctModalState === 'toggle' ? <>
                        <p>
                            <strong>WARNING</strong> disabling a CT will halt data acquisition for the unit. Until it is reactivated, readings in the interim will not be recorded.
                        </p>
                    </> 
                    : 
                    <>
                        <p>
                            Delete <strong>{props.ctModalData.name}</strong>? This action cannot be undone.
                        </p>
                    </>
                }
            </Modal.Body>

            <Modal.Footer>
                {props.ctModalState === 'add' && <Button style={{backgroundColor:'rgb(8,114,182)'}} onClick={addCT}>Create</Button>}
                {props.ctModalState === 'toggle' && <Button style={{backgroundColor:'rgb(8,114,182)'}} onClick={() => props.toggleCTStatus(props.ctModalData.id)}>Confirm</Button>}
                {props.ctModalState === 'delete' && <Button variant='danger' onClick={() => props.handleCTDeletion(props.ctModalData.id)}>Delete</Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default CTModal;