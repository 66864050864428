import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import TargetModal from './TargetModal';

const TargetRegistry = props => {
    const [targetModalState, setTargetModalState] = useState('');

    const closeModal = () => {
        setTargetModalState('');
    }

    return (
        <>
        <TargetModal targetModalState={targetModalState} closeModal={closeModal}/>
        <Table style={{margin:'auto', width:'98%'}}>
            <thead>
                <tr style={{backgroundColor:'rgb(8,114,182)', color:'white'}}>
                    <th>
                        <Button style={{backgroundColor:'rgba(0,0,0,0)', border:'none'}} onClick={() => setTargetModalState('add')}>Add</Button>
                    </th>
                    <th style={{textAlign:'center'}} colSpan={2}>Benchmarks</th>
                    <th colSpan={5} style={{textAlign:'center'}}>Reduction targets</th>
                </tr>
            </thead>
            <thead>
                <tr style={{backgroundColor:'rgb(0,177,168)', color:'white'}}>
                    <th></th>
                    <th style={{textAlign:'center'}}>Baseline year</th>
                    <th style={{textAlign:'center'}}>Target year</th>
                    <th style={{textAlign:'center'}}>Water</th>
                    <th style={{textAlign:'center'}}>Electricity</th>
                    <th style={{textAlign:'center'}}>Gas</th>
                    <th style={{textAlign:'center'}}>GHG</th>
                    <th style={{textAlign:'center'}}>Cost</th>
                </tr>
            </thead>
            <tbody>
                {/* {(noSensorMessage !== '' && sensors.length === 0) && <td colSpan={7}>
                    <div style={{backgroundColor:'lightgrey', height:'10vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <p style={{textAlign:'center'}}>{noSensorMessage}</p>
                    </div>
                </td>} */}

                
            </tbody>
        </Table>
        </>
    )
}

export default TargetRegistry;