import React from 'react';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";
import { electricUnitTypes } from '../../../../example_data/dropdowns/clientModal/loggingComponents';

const ParetoGraph = props => {
    const formatXAxisTick = tick => {
        return `${Math.round(100 * tick)}%`
    };

    const formatYAxisTick = tick => {
        for(let i = 0;i<electricUnitTypes.length;++i){
            if(electricUnitTypes[i].value === tick) return electricUnitTypes[i].text;
        }
    }

    const formatReadings = reading => {
        return `${(100 * Math.round(reading * 100) / 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
    }

    return(
        <div style={{width:'100%', height:'100%'}}>
            <div style={{height:'15%'}}>
                <h4>Electricity Pareto over last 24h</h4>
            </div>

            {props.data != null ? <ResponsiveContainer height='82%' width='100%'>
                <BarChart data={props.data} layout='vertical'>
                    <XAxis tickLine={false} axisLine={false} type='number' tickFormatter={formatXAxisTick}/>
                    <YAxis dataKey="name" tickLine={false} axisLine={false} type='category' width={125} tickFormatter={formatYAxisTick}/>
                    <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }}
                        labelFormatter={formatYAxisTick}
                        wrapperStyle={{ outline: "none" }}
                        formatter={formatReadings}
                    />
                    <Bar dataKey="percent" fill={props.primary}/>
                </BarChart>
            </ResponsiveContainer> :
            <div>Electricity not in scope</div>}
        </div>
    );
}

export default ParetoGraph;