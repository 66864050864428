import React, { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AlertLabsTable from '../tables/AlertLabsTable';

const ASBRAlertLabsModal = props => {
    const [addingSensor, setAddingSensor] = useState(false);
    const [sensorForm, setSensorForm] = useState({
        deviceType: 'flowie',
        name: '',
        nameError: '',
        deviceName: '',
        deviceNameError: '',
        locationName: '',
        locationNameError: ''
    });

    var pendingRequest = useRef(false);

    return (
        <Modal
            show={props.alertlabsModalOpen !== undefined}
            onHide={() => props.setAlertlabsModalOpen(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div>
                    <Button onClick={() => {
                        if(addingSensor){
                            setSensorForm({
                                deviceType: 'flowie',
                                name: '',
                                nameError: '',
                                deviceName: '',
                                deviceNameError: '',
                                locationName: '',
                                locationNameError: ''
                            });
                        }
                        setAddingSensor(!addingSensor);
                    }}>
                        {addingSensor ? 'Cancel' : 'Add sensor'}
                    </Button>

                    {
                        addingSensor && 
                        <Zoom in={addingSensor}>
                            <div style={{backgroundColor:'#f5f5f5', width:'100%', height:'fit-content', padding:'1%'}}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Device type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="flowie" control={<Radio checked={sensorForm.deviceType === 'flowie'} onClick={() => {
                                            setSensorForm(prev => ({
                                                ...prev,
                                                deviceType: 'flowie'
                                            }));
                                        }}/>} label="Flowie" />
                                        <FormControlLabel value="floodie" control={<Radio checked={sensorForm.deviceType === 'floodie'} onClick={() => {
                                            setSensorForm(prev => ({
                                                ...prev,
                                                deviceType: 'floodie'
                                            }));
                                        }}/>} label="Floodie" />
                                    </RadioGroup>
                                    </FormControl>
                                <TextField 
                                    label="Name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={sensorForm.name}
                                    onChange={e => {
                                        setSensorForm(prev => ({
                                            ...prev,
                                            name: e.target.value
                                        }));
                                    }}
                                    error={sensorForm.nameError !== ''}
                                    helperText={sensorForm.nameError}
                                />

                                <TextField 
                                    label="Device name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={sensorForm.deviceName}
                                    onChange={e => {
                                        setSensorForm(prev => ({
                                            ...prev,
                                            deviceName: e.target.value
                                        }));
                                    }}
                                    error={sensorForm.deviceNameError !== ''}
                                    helperText={sensorForm.deviceNameError}
                                />

                                <TextField 
                                    label="AlertLabs location name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={sensorForm.locationName}
                                    onChange={e => {
                                        setSensorForm(prev => ({
                                            ...prev,
                                            locationName: e.target.value
                                        }));
                                    }}
                                    error={sensorForm.locationNameError !== ''}
                                    helperText={sensorForm.locationNameError}
                                />

                                <Button onClick={async () => {
                                    if(pendingRequest.current) return;
                                    pendingRequest.current = true;

                                    let isValid = true;
                                    let sensorFormCopy = {...sensorForm};

                                    if(sensorFormCopy.name.trim() === ''){
                                        isValid = false;
                                        sensorFormCopy.nameError = 'Required';

                                    }else sensorFormCopy.nameError = '';

                                    if(sensorFormCopy.deviceName.trim() === ''){
                                        isValid = false;
                                        sensorFormCopy.deviceNameError = 'Required';
                                        
                                    }else sensorFormCopy.deviceNameError = '';

                                    if(sensorFormCopy.locationName.trim() === ''){
                                        isValid = false;
                                        sensorFormCopy.locationNameError = 'Required';
                                        
                                    }else sensorFormCopy.locationNameError = '';

                                    setSensorForm(sensorFormCopy);

                                    if(!isValid){
                                        pendingRequest.current = false;
                                        return;
                                    }

                                    let newSensor = await props.addAlertLabsSensor(sensorForm);

                                    if(newSensor == null){
                                        setSensorForm(prev => ({
                                            ...prev,
                                            nameError:'Could not add valve'
                                        }));
                                    }else{
                                        setSensorForm({
                                            deviceType: 'flowie',
                                            name: '',
                                            nameError: '',
                                            deviceName: '',
                                            deviceNameError: '',
                                            locationName: '',
                                            locationNameError: ''
                                        });
                                        setAddingSensor(false);
                                    }

                                    pendingRequest.current = false;
                                }}>Add</Button>
                            </div>
                        </Zoom>
                    }

                    <AlertLabsTable sensors={props.sensors} formatDate={props.formatDate} toggleAlertlabsSensor={props.toggleAlertlabsSensor}/>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ASBRAlertLabsModal;