import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Form } from 'react-bootstrap';

const AlertLabsTable = props => {
    const columns = [
        {
            id: 'name',
            label: 'Name',
            minWidth: 'fit-content',
            align: 'left'
        },
        {
            id: 'device_type',
            label: 'Type',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'last_sync',
            label: 'Last Sync',
            minWidth: 'fit-content',
            align: 'right'
        },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                <strong>{column.label}</strong>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.sensors.map((sensor, i) => (
                            <TableRow key={sensor.id}>
                                <TableCell align='left'>{sensor.name}</TableCell>
                                <TableCell align='center'>{sensor.device_type.substring(0,1).toUpperCase() + sensor.device_type.substring(1)}</TableCell>
                                <TableCell align='center'>
                                    <Form.Check
                                        type="switch"
                                        id={`asbr-sensor-switch-${i}`}
                                        className='centrica-table-switch'
                                        onClick={async () => {
                                            await props.toggleAlertlabsSensor(sensor.id);
                                        }}
                                        label={sensor.active ? 'active' : 'disabled'}
                                        checked={sensor.active}
                                    />
                                </TableCell>
                                <TableCell align='right'>{sensor.last_sync ? props.formatDate(sensor.last_sync) : 'N/A'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default AlertLabsTable;