import React, { useEffect, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DailyLog from './DailyLog';
import ActivityLogModal from './ActivityLogModal';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ActivityLogContainer = props => {
    const [containerHeight, setContainerHeight] = useState('95vh');

    const [logs, setLogs] = useState([]);
    const [selectedTime, setSelectedTime] = useState(dayjs());

    var init = useRef(true);

    const axiosProtect = useAxiosProtect();
    const [isLoading, setIsLoading] = useState(true);

    const [activityModalOpen, setActivityModalOpen] = useState(false);

    const {auth, persist} = useAuth();
    const [currentSite, setCurrentSite] = useState(null);
    const [siteList, setSiteList] = useState([]);

    const [open, setOpen] = useState('');

    useEffect(() => {
        const updateContainerHeight = () => {
            let navbar = document.querySelector('.navbar');
            let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];
  
            let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
            let windowHeight = window.innerHeight;
            let remainingHeight = windowHeight - navbarHeight;
  
            setContainerHeight(`${remainingHeight}px`);
        };
      
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
            window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        if(auth.role == process.env.REACT_APP_ES_ADMIN || auth.role == process.env.REACT_APP_ES_USER){
            setCurrentSite(window.location.href.split('/').slice(-1)[0]);
        }else{
            axiosProtect.get(`/auth/query/list-client-site-access?uid=${auth.id}`).then(response => {
                setSiteList(response.data.sites);
                if(response.data.sites.length === 0) return;
                else setCurrentSite(response.data.sites[0].id);
            });
        }
    }, []);

    useEffect(() => {
        if(currentSite == null) return;

        updateLogs(1);
    }, [currentSite])

    const addLog = payload => {
        payload.site_id = parseInt(currentSite);

        axiosProtect.post('/activity/activity-log', payload).then(response => {
            let newLog = response.data.newLog;
            let logTime = dayjs.unix(parseInt(newLog.activity_time));

            if(logTime.isSame(selectedTime, 'month')){
                let logDate = new Date(parseInt(newLog.activity_time) * 1000);
                let dayOfMonth = logDate.getDate();

                if(logs.length - dayOfMonth <= logs.length){
                    let index = logs.length - dayOfMonth;
                    let logCopy = [...logs];

                    let left = 0, right = logCopy[index].logs.length;
                    while(left < right){
                        const mid = Math.floor((left + right) / 2);
                        if(logCopy[index].logs[mid].activity_time > parseInt(newLog.activity_time)){
                            left = mid + 1;
                        }else{
                            right = mid;
                        }
                    }
                    
                    logCopy[index].logs.splice(left, 0, newLog);
                    logCopy[index].expanded = true;
                    setLogs(logCopy);
                }
            }

            setOpen('success');
            setActivityModalOpen(false);
        }).catch(err => {
            console.log(err)
            setOpen('error');
            setActivityModalOpen(false);
        })
    }

    const closeToast = (event, reason) => {
        if (reason === 'clickaway') return;
    
        setOpen('');
    }

    const updateLogState = index => {
        let tempLogs = [...logs];
        tempLogs[index].expanded = !tempLogs[index].expanded;
        setLogs(tempLogs);
    }

    const updateLogs = page => {
        setIsLoading(true);
        axiosProtect.get(`/activity/activity-log?pageNumber=${page}&site_id=${currentSite}`).then(response => {
            setLogs(response.data.logs);
            setIsLoading(false);

        }).catch(err => {

        });
    }

    const deleteLog = (logID, logsIndex, entryIndex) => {
        axiosProtect.delete(`/activity/activity-log?id=${logID}`).then(response => {
            let logCopy = [...logs];
            
            logCopy[logsIndex].logs.splice(entryIndex, 1);
            if(logCopy[logsIndex].logs.length === 0) logCopy[logsIndex].expanded = false;

            setLogs(logCopy);

            setOpen('delete-success');
        }).catch(err => {
            setOpen('delete-error');
        });
    }

    return (
        <div style={{padding:`1% ${(auth.role == process.env.REACT_APP_ES_ADMIN || auth.role == process.env.REACT_APP_ES_USER) ? 0 : 2}%`, backgroundColor:'#f5f5f5', height: containerHeight, overflowX:'hidden'}}>
            {activityModalOpen && <ActivityLogModal activityModalOpen={activityModalOpen} setActivityModalOpen={setActivityModalOpen} addLog={addLog}/*addTask={addTask} oppView={props.oppView} formatDate={formatDate} modalLoading={modalLoading}*//>}

            <div style={{width:'fit-content', display:'flex', flexDirection:'column'}}>
                <div style={{height:'fit-content', paddingBottom:'1vh', display:'flex', flexDirection:'row'}}>
                    {(auth.role !== process.env.REACT_APP_ES_ADMIN && auth.role !== process.env.REACT_APP_ES_USER) && <Box sx={{ minWidth: '5vw', marginBottom: '1vh' }}>
                        {
                            (auth.role !== process.env.REACT_APP_ES_ADMIN && auth.role !== process.env.REACT_APP_ES_USER) ?
                            <FormControl fullWidth>
                                <InputLabel shrink id="demo-simple-select-label">Site</InputLabel>
                                <Select
                                    value={currentSite}
                                    label='Site'
                                    onChange={e => {
                                        if(e.target.value == currentSite) return;
                                        setCurrentSite(e.target.value);
                                    }}
                                >
                                    {
                                        siteList.map((site, i) => (
                                            <MenuItem value={site.id.toString()}>{site.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl> :
                            <div></div>
                        }
                    </Box>}

                    <div style={{flex:'4', display: 'flex', justifyContent: 'center'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker label={"Activity in"} views={['month', 'year']} value={selectedTime} onChange={e => {
                                        if(e == null) return;
                                        if(isNaN(e['$y'])) return;

                                        setSelectedTime(e);

                                        // const now = dayjs();
                                        // const diffInMonths = now.diff(e, 'month') + 1;
                                        // console.log(now.diff(e, 'month'))
                                        // updateLogs(diffInMonths);

                                        const now = dayjs(); // Current date and time
                                        const diffInMonths = now.startOf('month').diff(e.startOf('month'), 'month') + 1; // Adjust to match your requirements
                                        console.log(diffInMonths);
                                        updateLogs(diffInMonths);
                                    }}
                                    // disableFuture
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>

                    <Button style={{backgroundColor:'rgba(8,114,182, 0.1)', flex:'0.5', height:'fit-content'}} onClick={() => setActivityModalOpen(true)}>Add Log</Button>
                </div>


                <div style={{width:'100%'}}>
                    {
                        isLoading ? 
                        <Box style={{width:'95vw', textAlign:'center', paddingTop:'2vh'}}>
                            <CircularProgress />
                        </Box> :
                        <DailyLog logs={logs} updateLogState={updateLogState} uid={auth.id} deleteLog={deleteLog}/>
                    }
                </div>
            </div>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} open={open === 'success'} autoHideDuration={4000} onClose={closeToast}>
                    <Alert onClose={closeToast} severity="success" sx={{ width: '100%' }}>
                        Log created
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} open={open === 'error'} autoHideDuration={4000} onClose={closeToast}>
                    <Alert onClose={closeToast} severity="error" sx={{ width: '100%' }}>
                        Could not create log
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} open={open === 'delete-success'} autoHideDuration={4000} onClose={closeToast}>
                    <Alert onClose={closeToast} severity="success" sx={{ width: '100%' }}>
                        Log deleted
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} open={open === 'delete-error'} autoHideDuration={4000} onClose={closeToast}>
                    <Alert onClose={closeToast} severity="error" sx={{ width: '100%' }}>
                        Could not delete log
                    </Alert>
                </Snackbar>
            </Stack>
        </div>
    );
}

export default ActivityLogContainer;