import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import useAuth from '../../../../../hooks/useAuth';
import useAxiosProtect from '../../../../../hooks/useAxiosProtect';

const EventsModal = props => {
    const [eventDate, setEventDate] = useState(dayjs(new Date().toLocaleString('sv-SE', { timeZoneName: 'short' }).slice(0, 16).replace(' ', 'T')));
    const [title, setTitle] = useState('');
    const [titleErr, setTitleErr] = useState('');
    const [category, setCategory] = useState('leak');
    const [description, setDescription] = useState('');
    const maxDescLength = 256;
    const maxTitleLength = 64;

    const {auth, persist} = useAuth();
    const axiosProtect = useAxiosProtect();

    const validateForm = () => {
        let isValid = true;

        if(title.trim() === ''){
            setTitleErr('Required');
            isValid = false;
        }else setTitleErr('');

        return isValid;
    }

    const formatTimestamp = date => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}000`;
    }

    const insertNewEvent = ev => {
        let events = [...props.events];

        const newEventDate = new Date(ev.event_time_utc);

        let insertIndex = events.findIndex(event => new Date(event.event_time_utc) < newEventDate);

        if(insertIndex === -1){
            events.push(ev);
        }else{
            events.splice(insertIndex, 0, ev);
        }

        props.setEvents(events);
    }

    const submitEvent = () => {
        if(!validateForm()) return;

        let payload = {
            event_time_utc: formatTimestamp(eventDate['$d']),
            title: title.trim(),
            category,
            description: description.trim(),
            siteID: parseInt(window.location.href.split('/').slice(-1)[0]),
            creatorUID: auth?.id
        };

        axiosProtect.post('/s/sites/add-event', payload).then(res => {
            insertNewEvent(res.data.newEvent);
            props.setEventsModalOpen(false);

        }).catch(err => props.setEventsModalOpen(false));
    }

    return(
        <Modal
            show={props.eventsModalOpen}
            onHide={() => props.setEventsModalOpen(false)}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4>Add new event</h4>
            </Modal.Header>

            <Modal.Body style={{overflow:'hidden'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                        <DateTimePicker
                            label="Time of event"
                            value={eventDate}
                            onChange={newDate => setEventDate(newDate)}
                        />
                    </DemoContainer>
                </LocalizationProvider>

                <br/>

                <TextField fullWidth label='Title' value={title} onChange={e => {if(e.target.value.length <= maxTitleLength) setTitle(e.target.value)}} error={titleErr !== ''} 
                helperText={titleErr === '' ? `${maxTitleLength - title.length} character${maxTitleLength - title.length === 1 ? '' : 's'} left` : titleErr}/>

                <br/>
                <br/>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        label="Category"
                        onChange={e => setCategory(e.target.value)}
                    >
                        <MenuItem value='leak'>Leak</MenuItem>
                        <MenuItem value='production'>Production incident</MenuItem>
                        <MenuItem value='malfunction'>Equipment malfunction</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                    </Select>
                </FormControl>

                <br/>
                <br/>

                <TextField 
                    fullWidth placeholder='Description (optional)' value={description} onChange={e => {if(e.target.value.length <=  maxDescLength) setDescription(e.target.value)}} 
                    multiline rows={2} helperText={`${maxDescLength - description.length} character${maxDescLength - description.length === 1 ? '' : 's'} left`}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button style={{border:'none',  backgroundColor:'rgb(8,114,182)'}} onClick={() => {
                    submitEvent();
                }}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EventsModal;