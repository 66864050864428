import React, {useEffect, useRef, useState} from 'react';
import Table from 'react-bootstrap/Table';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import { CancelRounded, Check } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useAxiosProtect from '../../../../../../hooks/useAxiosProtect';
import { FormHelperText } from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Form from 'react-bootstrap/Form';
import CTModal from './CTModal';

const CentricaRegistry = props => {
    const axiosProtect = useAxiosProtect();

    const [siteNameEditOn, setSiteNameEditOn] = useState(false);
    var centricaName = useRef('');
    const [centricaFieldValue, setCentricaFieldValue] = useState('');
    const [centricaFieldErr, setCentricaFieldErr] = useState('');

    const [sensors, setSensors] = useState([]);
    const [noSensorMessage, setNoSensorMessage] = useState('');

    const [ctModalState, setCTModalState] = useState('');
    const [ctModalData, setCTModalData] = useState(null);

    const [isCopied, setIsCopied] = useState(false);

    var init = useRef(true);
    const siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];

    useEffect(() => {
        if(init.current){
            init.current = false;

            const getCentricaData = async () => {
                try{
                    let response = await axiosProtect.get(`/ct/centrica/ct?site_id=${siteID}`);

                    if(response.status !== 200){
                        setNoSensorMessage('An error occurred');
                        return
                    }

                    centricaName.current = response.data.centrica_site_name;
                    setCentricaFieldValue(response.data.centrica_site_name);

                    if(response.data.cts.length === 1 && response.data.cts[0] == null){
                        setNoSensorMessage('No sensors available');
                        return
                    }

                    response.data.cts.sort((a, b) => {
                        let nameA = a.name.toLowerCase();
                        let nameB = b.name.toLowerCase();

                        return nameA < nameB ? -1 : 1;
                    });

                    setSensors(response.data.cts);
                    props.updateCTCount(true, response.data.cts.length)

                }catch(err){
                    setNoSensorMessage('An error occurred');
                    return;
                }
            }

            getCentricaData();
        }
    }, []);

    const handleClickToggleEdit = () => {
        if(siteNameEditOn){
            setCentricaFieldValue(centricaName.current);
        }

        setCentricaFieldErr('');
        setSiteNameEditOn(edit => !edit);
    }

    const handleMouseDownEdit = (event) => {
        event.preventDefault();
    };

    const updateCentricaName = async () => {
        let payload = {
            site_id: siteID,
            centrica_site_name: centricaFieldValue
        }

        try{
            let response = await axiosProtect.put(`/s/sites/centrica-name`, payload);

            if(response.status === 200){
                centricaName.current = response.data.centrica_site_name;
                handleClickToggleEdit();
            }
        }catch(err){
            setCentricaFieldErr(err.response.data.error)
        }
    }

    const addCT = ct => {
        let insertIndex = sensors.findIndex(sensor => sensor.name.localeCompare(
            ct.name, undefined, {sensitivity: 'base'}
        ) > 0);

        props.updateCTCount(true, 1);

        if(insertIndex === -1){
            setSensors(prev => [...prev, ct]);
            return;
        }

        setSensors(prev => [
            ...prev.slice(0, insertIndex),
            ct,
            ...prev.slice(insertIndex),
        ]);
    }

    const checkCTType = id => {
        for(let i = 0;i<sensors.length;++i){
            if(sensors[i].id == id){
                return sensors[i].centrica_device_name === undefined ? 'edgecom' : 'centrica';
            }
        }
    }

    const toggleCTStatus = async id => {
        let isCentricaCT = checkCTType(id) === 'centrica';

        let reqURL = isCentricaCT ? `/ct/centrica/ct/toggle?ct_id=${id}` : `/edge/edgecom/toggle?device_id=${id}&device_type=ct`;

        try{
            let response = await axiosProtect.post(reqURL);
            if(response.status === 200){
                let temp = [...sensors];

                for(let i = 0;i<sensors.length;++i){
                    if(sensors[i].id == id){
                        temp[i].active = !temp[i].active
                    }
                }

                setSensors(temp);
            }
        }catch(err){
            console.log(err);
        }

        setCTModalState('');
        setCTModalData(null);
    }

    const handleCTDeletion = async id => {
        let isCentricaCT = checkCTType(id) === 'centrica';

        let reqURL = isCentricaCT ? `/ct/centrica/ct/?ct_id=${id}` : `/edge/edgecom/?device_id=${id}&device_type=ct`;

        try{
            let response = await axiosProtect.delete(reqURL);
            if(response.status === 200){
                let temp = [];

                for(let i = 0;i<sensors.length;++i){
                    if(sensors[i].id == id) continue;
                    temp.push(sensors[i]);
                }

                setSensors(temp);
                props.updateCTCount(false, 1);
            }
        }catch(err){
            console.log(err);
        }

        setCTModalState('');
        setCTModalData(null);
    }

    const closeModal = () => {
        setCTModalState('');
    }

    const copyCentricaURL = () => {
        navigator.clipboard.writeText(`https://rmisbalancer.herokuapp.com/ct/centrica/ct/sync?id=${siteID}`);
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    }

    return(
        <div>
            {ctModalState !== '' && <CTModal ctModalState={ctModalState} closeModal={closeModal} siteID={siteID} addCT={addCT} ctModalData={ctModalData} 
            toggleCTStatus={toggleCTStatus} handleCTDeletion={handleCTDeletion}/>}

            {(centricaFieldValue !== '' && centricaFieldErr === '' && sensors.length > 0 && !siteNameEditOn) && <>
                <FormControl style={{width:'40%', marginLeft:'28%'}} variant="outlined">
                    <OutlinedInput
                        readOnly
                        variant="filled"
                        value={`https://rmisbalancer.herokuapp.com/ct/centrica/ct/sync?id=${siteID}`}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton style={{fontSize:'1rem'}} onClick={copyCentricaURL} edge="end">
                                    {isCopied ? 'Copied!' : 'Copy'}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    <FormHelperText>
                        Create export job and use this URL when all sensors have been added
                    </FormHelperText>
                </FormControl>
                <br/>
                <br/>
            </>}
            
            <div style={{display:'flex', flexDirection: 'row'}}>
                <div style={{flex:'0.5'}}></div>

                <div style={{flex:'1'}}>
                    <IconButton style={{height:'100%', width:'50%'}} onClick={() => setCTModalState('add')}>
                        <AddCircleIcon style={{color:'rgb(8,114,182)'}}/>
                    </IconButton>
                </div>

                <FormControl sx={{ flex:'6'}} variant="outlined">
                    <InputLabel htmlFor="centrica-site-name-field">Centrica site name</InputLabel>
                    <OutlinedInput
                        id="centrica-site-name-field"
                        disabled={!siteNameEditOn}
                        value={centricaFieldValue}
                        error={centricaFieldErr !== ''}
                        helperText={centricaFieldErr}
                        onChange={e => setCentricaFieldValue(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                {siteNameEditOn ? 
                                    <>
                                    <IconButton onClick={updateCentricaName}>
                                        <Check/>
                                    </IconButton>
                                    <IconButton edge="end" onClick={handleClickToggleEdit}>
                                        <CancelRounded/>
                                    </IconButton>
                                    </> 
                                    :
                                    <IconButton onClick={handleClickToggleEdit} onMouseDown={handleMouseDownEdit} edge="end">
                                        <EditIcon />
                                    </IconButton>
                                }
                            </InputAdornment>
                        }
                        label="Centrica site name"
                    />
                    {centricaFieldErr !== '' && <FormHelperText error>
                        {centricaFieldErr}
                    </FormHelperText>}
                </FormControl>

                <div style={{flex:'0.5'}}></div>

                <div style={{flex:'8'}}>
                    <small style={{margin:'0'}}>
                        'Centrica site name' refers to the name of the site registered on <a href='https://www.powerradar.energy/' target='_blank'>Power Radar</a>. 
                        Copy+paste the name to avoid discrepancies.
                    </small>
                </div>

                <div style={{flex:'0.5'}}></div>
            </div>

            <br/>
             
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Voltage</th>
                        <th>Power factor</th>
                        <th>Supplier</th>
                        <th>Registered device name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {(noSensorMessage !== '' && sensors.length === 0) && <td colSpan={7}>
                        <div style={{backgroundColor:'lightgrey', height:'10vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <p style={{textAlign:'center'}}>{noSensorMessage}</p>
                        </div>
                    </td>}

                    {sensors?.map((ct, i) => (
                        <tr>
                            <td>{ct.name}</td>
                            <td>
                                <Form.Check
                                    type="switch"
                                    id={`centrica-ct-active-switch-${i}`}
                                    className='centrica-table-switch'
                                    onClick={() => {
                                        if(ct.active){
                                            setCTModalData(ct);
                                            setCTModalState('toggle');
                                        }else{
                                            toggleCTStatus(ct.id);
                                        }
                                    }}
                                    label={ct.active ? 'active' : 'disabled'}
                                    checked={ct.active}
                                />
                            </td>
                            <td>{ct.voltage}</td>
                            <td>{ct.power_factor}</td>
                            {/* <td>unit_type here</td> */}
                            <td>{ct.centrica_device_name === undefined ? 'Edgecom' : 'Centrica'}</td>
                            <td>{ct.centrica_device_name === undefined ? ct.edgecom_device_name : ct.centrica_device_name}</td>
                            <td>
                                <IconButton style={{height:'fit-parent', width:'auto'}} onClick={() => {
                                    setCTModalData(ct);
                                    setCTModalState('delete');
                                }}>
                                    <DeleteTwoToneIcon style={{fill: "red"}}/>
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default CentricaRegistry;