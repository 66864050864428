import React, { useEffect, useRef, useState } from 'react';
import Gauge from './Gauge';
import UtilitySavingsCard from './UtilitySavingsCard';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import SavingsGraph from './SavingsGraph';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const OverviewContainer = props => {
    const axiosProtect = useAxiosProtect();

    var init = useRef(true);
    const [isLoading, setIsLoading] = useState(true);

    const [timeframe, setTimeframe] = useState('month');
    const [timeRange, setTimeRange] = useState({start: -1, end: -1});
    const [rangeTitles, setRangeTitles] = useState({start: '', end: '', infoText: ''});

    const [utilitySavings, setUtilitySavings] = useState({
        water: {amount: 0, ghg: 0, cost: 0},
        electricity: {amount: 0, ghg: 0, cost: 0},
        gas: {amount: 0, ghg: 0, cost: 0}
    });

    const [savingsTimeseries, setSavingsTimeseries] = useState([]);

    const [gaugeTargets, setGaugeTargets] = useState({
        utilities: {water: 0, electricity: 0, gas: 0},
        ghg: 0,
        cost: 0
    });

    const [scope, setScope] = useState({
        hasWater: false,
        hasElectricity: false,
        hasGas: false,
    });

    const [activeUtility, setActiveUtility] = useState('Water');

    const [siteNames, setSiteNames] = useState([]);
    const [selectableSites, setSelectableSites] = useState([]);
    const [siteLookup, setSiteLookup] = useState({});

    const handleSiteSelection = e => {
        const {target: {value}} = e;

        // if(value.length === 0) return;
        setIsLoading(true);

        setSiteNames(typeof value === 'string' ? value.split(',') : value);
    }

    const getUnixSeconds = dateString => {
        const date = new Date(`${dateString} UTC`);
        date.setUTCHours(0,0,0,0);

        return Math.floor(date.getTime() / 1000);
    }

    const formatDateLabel = dateString => {
        const date = new Date(dateString);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    const updateTimeRange = () => {
        let startString = '', endString = '';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        let infoText = '';

        let today = new Date();
        endString = today.toLocaleDateString(undefined, options);

        if(timeframe === 'month'){
            let firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            
            startString = firstOfMonth.toLocaleDateString(undefined, options);
            infoText = 'this month';

        }else if(timeframe === 'semi-annual'){
            let sixMonthsAgo = new Date(today);
            sixMonthsAgo.setMonth(today.getMonth() - 6);
            
            startString = sixMonthsAgo.toLocaleDateString(undefined, options);
            infoText = 'in last 6 months';

        }else if(timeframe === 'annual'){
            let oneYearAgo = new Date(today);
            oneYearAgo.setDate(today.getDate() - 365);
            
            startString = oneYearAgo.toLocaleDateString(undefined, options);
            infoText = 'in last year';

        }else if(timeframe === 'ytd'){
            let firstOfYear = new Date(today.getFullYear(), 0, 1);
            
            startString = firstOfYear.toLocaleDateString(undefined, options);
            infoText = 'this year';

        }else if(timeframe === 'alltime'){
            let firstOfMonth = new Date(1970, 0, 1);
            
            startString = firstOfMonth.toLocaleDateString(undefined, options);
            infoText = 'since inception';

        }

        setTimeRange(prev => ({
            ...prev,
            ['start']: getUnixSeconds(startString),
            ['end']: getUnixSeconds(endString),
            
        }));

        setRangeTitles(prev => ({
            ...prev,
            ['start']: startString,
            ['end']: endString,
            ['infoText']: infoText
        }));
    }

    // useEffect(() => {
    //     if(!init.current) return;
    //     init.current = false;

        
    // }, []);

    useEffect(() => {
        setIsLoading(true);
        updateTimeRange();
    }, [timeframe]);

    const safeDivide = (num, denom) => {
        if(denom === 0) return 0;
        return num / denom;
    }

    useEffect(() => {
        if(timeRange.start === -1 || timeRange.end === -1) return;

        if(window.location.pathname.startsWith('/sites')){
            let siteID = window.location.href.split('/').slice(-1)[0]
            axiosProtect.get(`/tg/targets/overview-dashboard?start=${timeRange.start}&end=${timeRange.end}8&sites=${siteID}`).then(response => {
                if(response.data.savingsTimeseries.length > 0){
                    setRangeTitles(prev => ({
                        ['start']: formatDateLabel(response.data.savingsTimeseries[0].time.split(' ')[0]),
                        ['end']: formatDateLabel(response.data.savingsTimeseries[response.data.savingsTimeseries.length - 1].time.split(' ')[0]),
                    }));
                }

                setSavingsTimeseries(response.data.savingsTimeseries);
                setUtilitySavings(response.data.totalSavings);
                
                setGaugeTargets({
                    utilities: {
                        water: Math.round(100 * safeDivide(response.data.totalSavings.water.amount, response.data.targets.water)),
                        electricity: Math.round(100 * safeDivide(response.data.totalSavings.electricity.amount, response.data.targets.electricity)),
                        gas: Math.round(100 * safeDivide(response.data.totalSavings.gas.amount, response.data.targets.gas)),
                    },
                    ghg: Math.round(100 * safeDivide(
                        response.data.totalSavings.water.ghg + response.data.totalSavings.electricity.ghg + response.data.totalSavings.gas.ghg,
                        response.data.targets.ghg
                    )),
                    cost: Math.round(100 * safeDivide(
                        response.data.totalSavings.water.cost + response.data.totalSavings.electricity.cost + response.data.totalSavings.gas.cost,
                        response.data.targets.cost
                    ))
                })

                setIsLoading(false);
            }).catch(err => {});
        }else{
            axiosProtect.get(`/auth/query/list-client-site-access?uid=${props.userData.id}`).then(response => {
                let siteNameArr = [];
                let siteLookupObj = {};
                for(let i = 0;i<response.data.sites.length;++i){
                    siteNameArr.push(response.data.sites[i].name)
                    siteLookupObj[response.data.sites[i].name] = response.data.sites[i].id
                }

                setSiteNames(siteNameArr);
                setSelectableSites(siteNameArr);
                setSiteLookup(siteLookupObj);

                axiosProtect.get(`/tg/targets/overview-dashboard?start=${timeRange.start}&end=${timeRange.end}8&sites=${Object.values(siteLookupObj).join(',')}`).then(response => {
                    if(response.data.savingsTimeseries.length > 0){
                        setRangeTitles(prev => ({
                            ['start']: formatDateLabel(response.data.savingsTimeseries[0].time.split(' ')[0]),
                            ['end']: formatDateLabel(response.data.savingsTimeseries[response.data.savingsTimeseries.length - 1].time.split(' ')[0]),
                        }));
                    }

                    setSavingsTimeseries(response.data.savingsTimeseries);
                    setUtilitySavings(response.data.totalSavings);
                    
                    setGaugeTargets({
                        utilities: {
                            water: Math.round(100 * safeDivide(response.data.totalSavings.water.amount, response.data.targets.water)),
                            electricity: Math.round(100 * safeDivide(response.data.totalSavings.electricity.amount, response.data.targets.electricity)),
                            gas: Math.round(100 * safeDivide(response.data.totalSavings.gas.amount, response.data.targets.gas)),
                        },
                        ghg: Math.round(100 * safeDivide(
                            response.data.totalSavings.water.ghg + response.data.totalSavings.electricity.ghg + response.data.totalSavings.gas.ghg,
                            response.data.targets.ghg
                        )),
                        cost: Math.round(100 * safeDivide(
                            response.data.totalSavings.water.cost + response.data.totalSavings.electricity.cost + response.data.totalSavings.gas.cost,
                            response.data.targets.cost
                        ))
                    })

                    init.current = false;
                    setIsLoading(false);
                }).catch(err => {});
            }).catch(err => {});
        }
    }, [timeRange]);

    useEffect(() => {
        if(init.current) return;

        let siteIDs = [];

        for(let i = 0;i<siteNames.length;++i){
            siteIDs.push(siteLookup[siteNames[i]]);
        }

        axiosProtect.get(`/tg/targets/overview-dashboard?start=${timeRange.start}&end=${timeRange.end}8&sites=${siteIDs.join(',')}`).then(response => {
            if(response.data.savingsTimeseries.length > 0){
                setRangeTitles(prev => ({
                    ['start']: formatDateLabel(response.data.savingsTimeseries[0].time.split(' ')[0]),
                    ['end']: formatDateLabel(response.data.savingsTimeseries[response.data.savingsTimeseries.length - 1].time.split(' ')[0]),
                }));
            }

            setSavingsTimeseries(response.data.savingsTimeseries);
            setUtilitySavings(response.data.totalSavings);
            
            setGaugeTargets({
                utilities: {
                    water: Math.round(100 * safeDivide(response.data.totalSavings.water.amount, response.data.targets.water)),
                    electricity: Math.round(100 * safeDivide(response.data.totalSavings.electricity.amount, response.data.targets.electricity)),
                    gas: Math.round(100 * safeDivide(response.data.totalSavings.gas.amount, response.data.targets.gas)),
                },
                ghg: Math.round(100 * safeDivide(
                    response.data.totalSavings.water.ghg + response.data.totalSavings.electricity.ghg + response.data.totalSavings.gas.ghg,
                    response.data.targets.ghg
                )),
                cost: Math.round(100 * safeDivide(
                    response.data.totalSavings.water.cost + response.data.totalSavings.electricity.cost + response.data.totalSavings.gas.cost,
                    response.data.targets.cost
                ))
            })

            setIsLoading(false);
        }).catch(err => {});
    }, [siteNames]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    return (
        <div style={{height:'fit-content',backgroundColor:'#f5f5f5', overflowX:'hidden'}}>
            <h4 style={{textAlign:'center'}}>Emissions savings</h4>

            <div style={{height:'fit-content', width:'100%', padding:'2vh 1vw 1vh 1vw', display:'flex', flex:'wrap'}}>
                <Box>
                    <FormControl style={{width:'15vw', minWidth:'fit-content'}}>
                        <InputLabel id="demo-simple-select-label">Timeframe</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={timeframe}
                            label="Timeframe"
                            onChange={e => setTimeframe(e.target.value)}
                        >
                            <MenuItem value='month'>This month</MenuItem>
                            <MenuItem value='semi-annual'>Last 6 months</MenuItem>
                            <MenuItem value='annual'>Last year</MenuItem>
                            <MenuItem value='ytd'>YTD</MenuItem>
                            <MenuItem value='alltime'>All time</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {
                    isLoading ?
                    <Skeleton style={{marginLeft:'1vw', width:'15vw'}}/>
                    :
                    <p style={{margin:'0',paddingTop:'1rem', paddingLeft:'1vw'}}>Showing results from {rangeTitles.start} - {rangeTitles.end}</p>
                }

                {
                    (props.userData.role === process.env.REACT_APP_CLIENT_ADMIN || props.userData.role === process.env.REACT_APP_CLIENT_STAFF) && 
                    <>
                    <div style={{flex:'1'}}></div>

                    <div>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Sites</InputLabel>
                            <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={siteNames}
                            onChange={handleSiteSelection}
                            input={<OutlinedInput label="Sites" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            >
                            {selectableSites.map(site => (
                                <MenuItem key={site} value={site}>
                                    <Checkbox checked={siteNames.includes(site)} />
                                    <ListItemText primary={site} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    </>
                }
            </div>

            <div style={{display:'flex', flexDirection:'row', height:'fit-content', width:'100%'}}>
                <div style={{flex:'1', padding:'1vw'}}>
                    <UtilitySavingsCard utility='Water' isLoading={isLoading} infoText={rangeTitles.infoText} units='m3' metrics={[utilitySavings.water.amount, utilitySavings.water.ghg, utilitySavings.water.cost]}/>
                </div>
                <div style={{flex:'1', padding:'1vw'}}>
                    <UtilitySavingsCard utility='Electricity' isLoading={isLoading} infoText={rangeTitles.infoText} units='kWh' metrics={[utilitySavings.electricity.amount, utilitySavings.electricity.ghg, utilitySavings.electricity.cost]}/>
                </div>
                <div style={{flex:'1', padding:'1vw'}}>
                    <UtilitySavingsCard utility='Natural gas' isLoading={isLoading} infoText={rangeTitles.infoText} units='m3' metrics={[utilitySavings.gas.amount, utilitySavings.gas.ghg, utilitySavings.gas.cost]}/>
                </div>
            </div>

            <br/>

            <div style={{display:'flex', flexDirection:'row', height:'fit-content', width:'100%'}}>
                <div style={{flex:'1'}}>
                    <Box style={{textAlign:'center'}}>
                        <FormControl style={{width:'fit-content', minWidth:'fit-content'}} variant='standard'>
                            <Select
                                value={activeUtility}
                                onChange={e => setActiveUtility(e.target.value)}
                            >
                                <MenuItem value='Water'>Water savings against reduction target</MenuItem>
                                <MenuItem value='Electricity'>Electricity savings against reduction target</MenuItem>
                                <MenuItem value='Gas'>Gas savings against reduction target</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>


                    <Gauge pct={gaugeTargets.utilities[activeUtility.toLowerCase()]} utility={activeUtility}/>
                </div>
                <div style={{flex:'1', textAlign:'center'}}>
                    <p style={{margin:'0'}}>GHG savings against reduction target</p>
                    <Gauge pct={gaugeTargets.ghg}/>

                </div>
                <div style={{flex:'1', textAlign:'center'}}>
                    <p style={{margin:'0'}}>Cost savings against reduction target</p>
                    <Gauge pct={gaugeTargets.cost}/>
                </div>
            </div>
            <div style={{height:'40vh', width:'100%', padding:'1vh 1vw', marginBottom:'2vh', marginTop:'-5vh'}}>
                <SavingsGraph title="Water" utility='water' data={savingsTimeseries} isLoading={isLoading}/>
            </div>
        </div>
    );
}

export default OverviewContainer;