import React, { useState, useEffect } from 'react';
import FeedbackPage from './feedback/FeedbackPage';

const CustomerManagementContainer = props => {
    const [containerHeight, setContainerHeight] = useState('95vh');

    useEffect(() => {
        const updateContainerHeight = () => {
            let navbar = document.querySelector('.navbar');
            let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];
  
            let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
            let windowHeight = window.innerHeight;
            let remainingHeight = windowHeight - navbarHeight;
  
            setContainerHeight(`${remainingHeight}px`);
        };
      
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
            window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    return (
        <div style={{padding:'1% 2%', backgroundColor:'#f5f5f5', minHeight:containerHeight, height:'fit-content'}}>
            <FeedbackPage/>
        </div>
    );
}

export default CustomerManagementContainer;