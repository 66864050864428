import React from 'react';
import CountUp from 'react-countup';
import { Skeleton } from '@mui/material';

const UtilitySavingsCard = props => {
    return(
        <div style={{height:'100%', width:'100%', padding:'1% 2%', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}>
            <h4>{props.utility}</h4>

            <div>
                {
                    props.isLoading 
                    ?
                    <>
                        <Skeleton style={{height:'5vh'}}/>
                    </>
                    :
                    <>
                        <span className='metric-span' style={{margin:'0'}}><CountUp separator=',' style={{color:'rgb(8,114,182)', fontSize:'5vh', margin:'0'}} end={props.metrics[0]} duration={1}/> {props.units}</span>
                        <span style={{marginLeft:'4%', alignSelf: 'flex-end'}}>saved {props.infoText}</span>
                    </>
                }
            </div>

            <div>
                {
                    props.isLoading 
                    ?
                    <>
                        <Skeleton style={{height:'5vh'}}/>
                    </>
                    :
                    <>
                        <span className='metric-span' style={{margin:'0'}}><CountUp separator=',' style={{color:'rgb(0,177,168)', fontSize:'5vh', margin:'0'}} end={props.metrics[1]} duration={1}/> {'kg'}</span>
                        <span style={{marginLeft:'4%', alignSelf: 'flex-end'}}>of CO<sub>2</sub>eq saved</span>
                    </>
                }
            </div>

            <div>
                {
                    props.isLoading 
                    ?
                    <>
                        <Skeleton style={{height:'5vh'}}/>
                    </>
                    :
                    <>
                        <span className='metric-span' style={{margin:'0'}}><CountUp prefix='$' separator=',' style={{color:'rgb(3,103,4)', fontSize:'5vh', margin:'0'}} end={props.metrics[2]} duration={1}/></span>
                        <span style={{marginLeft:'4%', alignSelf: 'flex-end'}}>in financial savings</span>
                    </>
                }
            </div>
        </div>
    )
}

export default UtilitySavingsCard;