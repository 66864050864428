import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const RoofSegmentTable = props => {
    const columns = [
        {
            id: 'segment',
            label: 'Segment',
            minWidth: 'fit-content',
            align: 'left'
        },
        {
            id: 'area',
            label: 'Area (sqft)',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'flow rate',
            label: 'Drain flow rate (LPM)',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'height',
            label: 'Max water level (cm)',
            minWidth: 'fit-content',
            align: 'center'
        },
        {
            id: 'distance',
            label: 'Sensor distance (cm)',
            minWidth: 'fit-content',
            align: 'center'
        },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                <strong>{column.label}</strong>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.segments.map((segment, i) => (
                            <TableRow key={segment.id}>
                                <TableCell align='left'>{segment.name}</TableCell>
                                <TableCell align='center'>{Math.round(segment.area_sqft).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                <TableCell align='center'>{Number(Math.round(100 * segment.drain_flow_rate_lpm) / 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                <TableCell align='center'>{Number(Math.round(100 * segment.max_height_cm) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                <TableCell align='center'>{Number(Math.round(100 * segment.sensor_distance_cm) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default RoofSegmentTable;